import dynamic from 'next/dynamic'
import { PAGE_PATH_HOME } from '@bluheadless/ui-logic/src/constants'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useIntl } from 'react-intl'
import Breadcrumbs from '../../organisms/breadcrumbs'
import DynamicAreas from '../../particles/dynamic-areas'
import RichContent from '../../particles/rich-content'
import { Root, Title } from './cms-page.styled'

const ComparableProducts = dynamic(() => import('../../organisms/comparable-products'), { ssr: false })

const CmsPagePageComponent = ({
	layout: { breadcrumbsVisible, headingVisible } = {},
	contentHeading,
	title,
	content /* ,title , entityId, layout, dynamicAreas */,
	dynamicAreas,
}) => {
	const { formatMessage } = useIntl()
	const breadcrumbsItems = [{ title: formatMessage({ id: 'breadcrumbs_home' }), url: PAGE_PATH_HOME }, { title: title }]

	const {
		catalog: {
			products: {
				comparable: { enabled: comparableEnabled },
			},
		},
	} = useConfig()

	return (
		<Root>
			<DynamicAreas areas={dynamicAreas} container="top" />

			{headingVisible && contentHeading && (
				<Title variant="h1" mt={4}>
					{contentHeading}
				</Title>
			)}

			<DynamicAreas areas={dynamicAreas} container="middle" />

			<RichContent content={content} />

			<DynamicAreas areas={dynamicAreas} container="bottom" />

			{breadcrumbsVisible && <Breadcrumbs items={breadcrumbsItems} pt={2} />}

			{comparableEnabled && <ComparableProducts />}
		</Root>
	)
}

CmsPagePageComponent.defaultProps = {}

CmsPagePageComponent.propTypes = {}

export default CmsPagePageComponent
